export const extraOptions = [
    "dashboard",
    "clients",
    "categories",
    "products",
    "roles",
    "pos",
    "utilisateurs",
    "stockplus",
    "promotion_codes",
    "order_history",
    "supplier",
    "delivery",
    "expedition_categories",
    "expedition_mode",
    "taxes",
    "promo_flash",
    "brand",
    "opening_hours",
    "cat_exped",
    "mode_exped",
    "promo_flash",
    "advanced_stat",
    "stat_products",
    "stat_shipping_mode",
    "stats_visits",
    "brands",
    "sellers",
    "gestion-stock",
    "transfer",
    "arrivage"
];
