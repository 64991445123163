import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageStyle,
  InputStyle,
} from "./AddSubCategory.style";
import {
  ISubCategoryAddDialog,
  ISubCategoryAddingData,
  ISubCategoryAddingDataError,
} from "./AddSubCategory.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import {  useAddSubCategoryMutation, useGetSubCategoriesQuery } from "redux/api/category/category";
import FileInput from "components/common/FileInput/FileInput";
import { GlobalVariables } from "config/constant";

function AddSubCategory({ openSubCatAddingDialog, setOpenSubCatAddingDialog, category }: ISubCategoryAddDialog) {
  const { t } = useTranslation();
  const [parentCat,setParentCat]=useState<any>()
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
console.log("caaa",category?.[0].title);
  const [parent_category, { dataCat,errorCat}] = useAddSubCategoryMutation();
  if(dataCat){

    setParentCat(dataCat)
  }
  const [addingSubCategory, { data, error, isLoading, reset }] =
    useAddSubCategoryMutation();
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("category.sub_cat_successfully_adding"));
    reset();
  }

  const closeSubCatAddingDialog = useCallback(() => {
    setOpenSubCatAddingDialog(false);
    setAddingData({ title: "", image: null });
    setAddingError({ title: "", image: "" });

  }, [setOpenSubCatAddingDialog]);
  const validate=()=>{
    const addingErrorCateg:{title:string,image:string}={
      title:"",
      image:"",
    }
    if(!addingData.title){
      addingErrorCateg.title="Titre obligatoire"
    }
    else {
      addingErrorCateg.title=""

    }
    if(!addingData.image){
      addingErrorCateg.image="Image obligatoire"

    }
    else {
      addingErrorCateg.image=""

    }
    setAddingError(addingErrorCateg)
    console.log("AddingError",addingErrorCateg)

  }
  const [addingData, setAddingData] = useState<ISubCategoryAddingData>({
    title: "",
    image: null,
  });
  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
   validate();
    const formData = new FormData();
    formData.append("title", addingData.title);
    formData.append("categ_parent", `${category?.[0].id}`);
    if (addingData.image) formData.append("image", addingData.image);
  if(addingData.title && addingData.image){
   addingSubCategory({id:category?.[0].id,formData});
    closeSubCatAddingDialog();
  }
}
const onChangeOneImage = useCallback(
  (field: string) => (e: any) => {
    const files = (e.target as HTMLInputElement).files;

    if (files) {
      const file = files[0];

      // Check if the selected file is an image
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();

        reader.onload = (event) => {
          if (event.target) {
            const image = new Image();
            image.src = event.target.result as string;

            image.onload = () => {
              // Access the image dimensions
              const imageWidth = image.width;
              const imageHeight = image.height;

              console.log('Image Width:', imageWidth);
              console.log('Image Height:', imageHeight);
              if(imageWidth==300 && imageHeight == 300){
                setAddingError({ ...addingError, image: "" });

                setAddingData({
                  ...addingData,
                image : e.target.files[0]})


              }else{
                setAddingError({ ...addingError, image: "L'image doit être de taille 300 × 300 pixels." });

              }


            };
          }
        };

        // Read the selected file as a data URL
        reader.readAsDataURL(file);
      } else {
        // Handle non-image file type
        console.error('Selected file is not an image.');
      }
    }
  },
  [addingData]
);


  const [addingError, setAddingError] =
    useState<ISubCategoryAddingDataError>({
      title: "",
      image: "",
    });
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingData({ ...addingData, [field]: e.target.value });
        validate()

        if (field === "image") {
          const files = (e.target as HTMLInputElement).files;
          if (files)
            setAddingData({
              ...addingData,
              image: files[0],
            });

        }
      },
    [addingData]
  );

  return (
    <Dialog
      open={openSubCatAddingDialog}
      handleClose={closeSubCatAddingDialog}
      title={t("category.add_sub_cat")}
    >
      <form onSubmit={onSuccessAddingDialog}>
        <div style={{display:"inline-flex",alignItems:"center",justifyContent:"center"}}>
          <h3>
            Catégorie parente:
          </h3>
          <p>{category?.[0].title}</p>
        </div>
        <InputStyle>
          <TextInput
            value={addingData.title}
            label={t("category.title")}
            onChange={onChange("title")}
            error={t(addingError.title)}
          />
        </InputStyle>
        <InputStyle>
          <FileInput
            value={addingData.image}
            label={t("category.image")}
            onChange={onChangeOneImage("image")}
            error={t(addingError.image)}
            accept={GlobalVariables.FILE_FORMATS.map((e) => e.format).join(",")}
          />
        </InputStyle>
        {addingData.image && (
          <ImageContainerStyle>
            <ImageStyle
              src={URL.createObjectURL(addingData.image)}
            ></ImageStyle>
          </ImageContainerStyle>
        )}
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              title={t("common.cancel_button")}
              onClick={closeSubCatAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              type="submit"
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default AddSubCategory;
