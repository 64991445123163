import { RouteIdEnum } from "config/enums";
import { RouteObject } from "react-router-dom";
import NotFoundPage from "pages/NotFoundPage";
import LoginPage from "pages/Login/Login";
import PrivateRoute from "./PrivateRoute";
import Clients from "pages/Clients/Clients";
import PublicRoute from "./PublicRoute";
import PromotionCodesPage from "pages/PromotionCodes/PromotionCodes";
import CategoryPage from "pages/Category/Category";
import ProductPage from "pages/Product/Product";
import ProductAddPage from "pages/Product/ProductAdd";
import ProductEditPage from "pages/Product/ProductEdit";
import OrderPage from "pages/Order/Order";
import OpeningHoursPage from "pages/OpeningHours/OpeningHours";
import ChatPage from "pages/Chat/Chat";
import SuppliersPage from "pages/Supplier/Supplier";
import SubCategoryPage from "pages/SubCategory/SubCategory";
import DeliveryPage from "pages/Delivery/Delivery";
import ExpeditionModePage from "pages/ExpeditionMode/ExpeditionMode";
import AddExpeditonMode from "features/ExpeditionMode/ExpeditionModeAdd/AddExpeditionMode";
import TaxPage from "pages/Tax/Tax";
import EditExpeditionMode from "features/ExpeditionMode/ExpeditionModeEdit/EditExpeditionMode";
import ExpeditionCategoryPage from "pages/ExpeditionCategory/ExpeditionCategory";
import PromotionFlashesPage from "pages/PromotionFlash/PromotionFlash";
import DashboardPage from "pages/Dashboard/Dashboard";
import AddValuesExpeditionCategories from "features/AddValuesExpeditionCategories/AddValuesExpeditionCategories";
import UserDataChart from "pages/Dashboard/UserDataChart";
import BrandPage from "pages/Brand/Brand";
import DashboardStatsVisitPage from "pages/Dashboard/DashboardStatsVisits";
import Vendeurs from "UpdatedFeatures/Vendeurs/Vendeurs";
import Pos from "UpdatedFeatures/POS/Pos";
import StockPlus from "UpdatedFeatures/StockPlus/StockPlus";
import Roles from "UpdatedFeatures/Roles Management/Roles";
import Stock from "UpdatedFeatures/Stock Management/Stock";
import AddArrivage from "UpdatedFeatures/Stock Management/AddArrivage";
import Index from "UpdatedFeatures/Transfer Section/Index";
import AddTransfer from "UpdatedFeatures/Transfer Section/AddTransfer";

const RoutesConfig: RouteObject[] = [
  {
    path: RouteIdEnum.Root,
    element: <PrivateRoute requiredRoles={'dashboard'} element={<DashboardPage />} />,
  },
  {
    path: RouteIdEnum.Stats_Visit,
    element: <PrivateRoute requiredRoles={'dashboard'} element={<DashboardStatsVisitPage />} />,
  },
  {
    path: RouteIdEnum.Clients,
    element: <PrivateRoute requiredRoles={'clients'} element={<Clients />} />,
  },
  {
    path: RouteIdEnum.Categories,
    element: <PrivateRoute requiredRoles={'categories'} element={<CategoryPage />} />,
  },
  {
    path: RouteIdEnum.Products,
    children: [
      {
        path: RouteIdEnum.Add,
        element: <PrivateRoute requiredRoles={'products'} element={<ProductAddPage />} />,
      },
      {
        path: RouteIdEnum.Edit + "/" + RouteIdEnum.id,
        element: <PrivateRoute requiredRoles={'products'} element={<ProductEditPage />} />,
      },
      {
        path: RouteIdEnum.Root,
        element: <PrivateRoute requiredRoles={'products'} element={<ProductPage />} />,
      },
    ],
  },
  {
    path: RouteIdEnum.Arrivage,
    children: [
      {
        path: RouteIdEnum.Add,
        element: <PrivateRoute requiredRoles={'gestion-stock'} element={<AddArrivage />} />,
      },
      {
        path: RouteIdEnum.Root,
        element: <PrivateRoute requiredRoles={'gestion-stock'} element={<Stock />} />,
      },
    ],
  },
  {
    path: RouteIdEnum.Transfer,
    children: [
      {
        path: RouteIdEnum.Add,
        element: <PrivateRoute requiredRoles={'transfer'} element={<AddTransfer />} />,
      },
      {
        path: RouteIdEnum.Root,
        element: <PrivateRoute requiredRoles={'transfer'} element={<Index />} />,
      },
    ],
  },

  {
    path: RouteIdEnum.PromotionCodes,
    element: <PrivateRoute requiredRoles={'promotion-codes'} element={<PromotionCodesPage />} />,
  },
  {
    path: RouteIdEnum.Supplier,
    element: <PrivateRoute requiredRoles={'supplier'} element={<SuppliersPage />} />,
  },
  {
    path: RouteIdEnum.SubCategory,
    element: <PrivateRoute requiredRoles={'categories'} element={<SubCategoryPage />} />,
  },
  {
    path: RouteIdEnum.Expedition_Categories,
    element: <PrivateRoute requiredRoles={'delivery'} element={<ExpeditionCategoryPage />} />,
  },
  {
    path: `${RouteIdEnum.Expedition_Categories}/add`,
    element: <PrivateRoute requiredRoles={'delivery'} element={<AddValuesExpeditionCategories />} />,
  },
  {
    path: `${RouteIdEnum.Expedition_Mode}/add`,
    element: <PrivateRoute requiredRoles={'delivery'} element={<AddExpeditonMode />} />,
  },
  {
    path: `${RouteIdEnum.Expedition_Mode}/edit/:id`,
    element: <PrivateRoute requiredRoles={'delivery'} element={<EditExpeditionMode />} />,
  },
  {
    path: RouteIdEnum.Expedition_Mode,
    element: <PrivateRoute requiredRoles={'delivery'} element={<ExpeditionModePage />} />,
  },
  {
    path: RouteIdEnum.Opening_Hours,
    element: <PrivateRoute requiredRoles={'opening-hours'} element={<OpeningHoursPage />} />,
  },
  {
    path: RouteIdEnum.Chat,
    element: <PrivateRoute requiredRoles={'dashboard'} element={<ChatPage />} />,
  },
  {
    path: RouteIdEnum.OrderHistory,
    element: <PrivateRoute requiredRoles={'order-history'} element={<OrderPage />} />,
  },
  {
    path: RouteIdEnum.Brand,
    element: <PrivateRoute requiredRoles={'brand'} element={<BrandPage />} />,
  },
  {
    path: RouteIdEnum.Roles,
    element: <PrivateRoute requiredRoles={'roles'} element={<Roles />} />,
  },
  {
    path: RouteIdEnum.Promo_Flash,
    element: <PrivateRoute requiredRoles={'promo-flash'} element={<PromotionFlashesPage />} />,
  },
  {
    path: RouteIdEnum.Login,
    element: <PublicRoute element={<LoginPage />} />,
  },
  {
    path: RouteIdEnum.Dashboard,
    element: <PrivateRoute requiredRoles={'dashboard'} element={<DashboardPage />} />,
  },
  {
    path: `${RouteIdEnum.Dashboard}/stat/user/:id`,
    element: <PrivateRoute requiredRoles={'dashboard'} element={<UserDataChart />} />,
  },
  {
    path: RouteIdEnum.Advanced_Stat,
    element: <PrivateRoute requiredRoles={'dashboard'} element={<DashboardPage />} />,
  },
  {
    path: RouteIdEnum.Delivery,
    element: <PrivateRoute requiredRoles={'delivery'} element={<DeliveryPage />} />,
  },
  {
    path: RouteIdEnum.Tax,
    element: <PrivateRoute requiredRoles={'taxes'} element={<TaxPage />} />,
  },
  {
    path: RouteIdEnum.POS,
    element: <PrivateRoute requiredRoles={'pos'} element={<Pos />} />,
  },
  {
    path: RouteIdEnum.Vendeurs,
    element: <PrivateRoute requiredRoles={'utilisateurs'} element={<Vendeurs />} />,
  },
  {
    path: RouteIdEnum.stockPlus,
    element: <PrivateRoute requiredRoles={'stockPlus'} element={<StockPlus />} />,
  },
  {
    path: RouteIdEnum.Any,
    element: <NotFoundPage />,
  },
];

export default RoutesConfig;
