import { useMemo } from 'react';

const STATUS = {
    Pending: 0,
    Approved: 1,
    Active: 2,
};

const STATUS_DETAILS = {
    [STATUS.Pending]: {
        arrivage: { text: 'En cours d’arrivage', bgColor: 'bg-yellow-500/10', textColor: 'text-yellow-400', ringColor: 'ring-yellow-500/20' },
        default: { text: 'En cours de transfert', bgColor: 'bg-yellow-500/10', textColor: 'text-yellow-400', ringColor: 'ring-yellow-500/20' },
    },
    [STATUS.Approved]: {
        arrivage: { text: 'Partiellement réceptionné', bgColor: 'bg-blue-500/10', textColor: 'text-blue-400', ringColor: 'ring-blue-500/20' },
        default: { text: 'Partiellement transféré', bgColor: 'bg-blue-500/10', textColor: 'text-blue-400', ringColor: 'ring-blue-500/20' },
    },
    [STATUS.Active]: {
        arrivage: { text: 'Réceptionné', bgColor: 'bg-green-500/10', textColor: 'text-green-400', ringColor: 'ring-green-500/20' },
        default: { text: 'Transféré', bgColor: 'bg-green-500/10', textColor: 'text-green-400', ringColor: 'ring-green-500/20' },
    },
    default: {
        default: { text: 'Unknown', bgColor: 'bg-gray-500/10', textColor: 'text-gray-400', ringColor: 'ring-gray-500/20' },
    },
};

const Badge = ({ status, type = 'default' }) => {
    const { text, bgColor, textColor, ringColor } = useMemo(() => {
        return STATUS_DETAILS[status]?.[type] || STATUS_DETAILS[status]?.default || STATUS_DETAILS.default.default;
    }, [status, type]);

    return (
        <span
            className={`inline-flex items-center w-fit rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}`}
        >
            {text}
        </span>
    );
};

export default Badge;
