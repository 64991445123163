import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import TrashedSwitch from "UpdatedComponents/TrashedSwitch";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
  CategoryImageStyle,
} from "./Tax.style";
import { Add, Delete, Edit } from "@mui/icons-material";
import useCategoryList from "./useTaxList";
import { CONFIG } from "config/config";
import { useCallback, useEffect, useState } from "react";
import DeleteTax from "../TaxDelete/DeleteTax";
import AddTax from "../TaxAdd/AddTax";
import EditTax from "../TaxEdit/EditTax";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AddSubCategory from "features/SubCategory/SubCategoryAdd/AddSubCategory";
import { ITaxData } from "./Tax.types";
import useTaxList from "./useTaxList";
import { Checkbox } from "@mui/material";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import DeleteTaxMultiple from "../TaxDeleteMultiple/DeleteTaxMultiple";
import { useDeleteMultipleTaxesMutation } from "redux/api/tax/taxes";
import { useSnackBar } from "hooks/useSnackBar";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { restoreTax } from "UpdatedFeatures/api";
import { MdOutlineRestorePage } from "react-icons/md";
import RestoreModal from "UpdatedFeatures/RestoreModal";

function Tax() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    taxesData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    isArchived,
    setIsArchived,
    refetch,
  } = useTaxList();
  const [tax, setTax] = useState<ITaxData>({
    id: 0,
    name: "",
    percentage: "",
    country: "",
    created_at: "",
    updated_at: "",
  });

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedTax, setDeletedTax] = useState<{
    id: number;
    name: string;
  }>();
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [isAddSubCat, setIsAddSubCat] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);
  };

  const openDeleteDialogStatus = useCallback(
    (id: number, name: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedTax({ id: id, name: name });
    },
    [setOpenDeleteDialog, setDeletedTax]
  );
  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false);
  const openDeleteMultipleDialogStatus = () => {
    setOpenDeleteMultipleModal(true);
  };
  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (event.target.checked) {
      setCheckedItems((prevState: number[]) => [...prevState, id]);
    } else {
      setCheckedItems((prevState: number[]) =>
        prevState.filter((item: number) => item !== id)
      );
    }
  };
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);
  const openAddingSubCatDialogStatus = useCallback((tax: ITaxData) => {
    setOpenAddingDialog(true);
    setTax({ ...tax });
  }, []);
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedTax, setEditedTax] = useState<{
    id: number;
    name: string;
    percentage: string;
    country: string;
  }>();
  const [deleteCode, { data, error, isLoading: loading, reset }] =
    useDeleteMultipleTaxesMutation();
  const openEditingDialogStatus = useCallback(
    (id: number, name: string, percentage: string, country: string) => () => {
      setEditedTax({ id, name, percentage, country });
      setOpenEditingDialog(true);
    },
    []
  );
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("tax.successfully_deleted"));
    reset();
  }
  const restoreMutation = useMutation({
    mutationFn: (id: number) => restoreTax(id),
    onSuccess: () => {
      toast.success(t("Tax a été restaurée avec succès !"));
      refetch();
      setRestoreModalOpen(false);
    },
    onError: (error) => {
      toast.error(t("Erreur lors de la restauration du Tax"));
      setRestoreModalOpen(false);
    },
  });
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [taxtToRestore, setTaxtToRestore] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const handleRestoreClick = (tax: ITaxData) => {
    setTaxtToRestore({ id: tax.id, name: tax.name });
    setRestoreModalOpen(true);
  };

  const handleRestoreConfirm = () => {
    if (taxtToRestore) {
      restoreMutation.mutate(taxtToRestore.id);
    }
  };
  const deleteMultiple = (event: any) => {
    event.preventDefault();
    deleteCode({
      tax_ids: selectedItems,
      select_all: selectAll,
      search: search,
    });

    if (!error) {
      setOpenDeleteMultipleModal(false);
      reset();
    }
  };
  return (
    <DashboardLayout title={t("tax.page_title")} selectedPath={RouteIdEnum.Tax}>
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <ButtonContainerStyle item xs={12} sm={4}>
          {(selectAll || selectedItems.length != 0) && (
            <DeleteButton
              onAddClick={openDeleteMultipleDialogStatus}
              title={t("common.delete")}
            />
          )}
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex  items-center gap-5">
            <TrashedSwitch enabled={isArchived} setEnabled={setIsArchived} />
            &nbsp;
            <AddButton
              onAddClick={openAddingDialogStatus}
              title={t("common.add")}
            />
          </div>
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: (
              <Checkbox
                color="secondary"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            ),
          },
          {
            key: "name",
            title: t("tax.name"),
            onClick: onOrderChange("name"),
            asc: orderBy === "name" ? orderDir === "asc" : undefined,
          },
          {
            key: "percentage",
            title: t("tax.percentage"),
            onClick: onOrderChange("percentage"),
            asc: orderBy === "name" ? orderDir === "asc" : undefined,
          },
          {
            key: "country",
            title: t("tax.country"),
            onClick: onOrderChange("country"),
            asc: orderBy === "country" ? orderDir === "asc" : undefined,
          },
          {
            key: "created_at",
            title: t("category.created_at"),
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={taxesData?.data.map((e: ITaxData) => {
          return {
            checkbox: (
              <Checkbox
                checked={
                  (!selectAll && selectedItems.includes(e.id)) ||
                  (selectAll && !selectedItems.includes(e.id))
                }
                onChange={() => handleCheckboxChange(e.id)}
              />
            ),

            name: <p> {e.name}</p>,
            percentage: <p>{e.percentage}</p>,
            country: <p>{e.country}</p>,
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            action: isArchived ? (
              <Tooltip title={"Restorer"} placement="top">
                <MdOutlineRestorePage
                  size={30}
                  className="text-[#b369ae] hover:cursor-pointer "
                  onClick={() => handleRestoreClick(e)}
                />
              </Tooltip>
            ) : (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(
                        e.id,
                        e.name,
                        e.percentage,
                        e.country
                      )}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.name)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: taxesData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {deletedTax && (
        <DeleteTax
          id={deletedTax.id}
          name={deletedTax.name}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteTax>
      )}
      <AddTax
        openAddingDialog={openAddingDialog}
        setOpenAddingDialog={setOpenAddingDialog}
      ></AddTax>
      {selectedItems && (
        <DeleteTaxMultiple
          isLoading={loading}
          taxes_ids={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteTaxMultiple>
      )}

      {editedTax && (
        <EditTax
          iniData={{
            id: editedTax.id,
            name: editedTax.name,
            percentage: editedTax.percentage,
            country: editedTax.country,
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditTax>
      )}
      {taxtToRestore && (
        <RestoreModal
          open={restoreModalOpen}
          onConfirm={handleRestoreConfirm}
          onCancel={() => setRestoreModalOpen(false)}
          itemTitle={taxtToRestore.name}
        />
      )}
    </DashboardLayout>
  );
}
export default Tax;
