import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { ISubCategoryData } from "./SubCategory.types";
import { Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
  CategoryImageStyle,
} from "./SubCategory.style";
import { Add, Delete, Edit } from "@mui/icons-material";
import useSubCategoryList from "./useSubCategoryList";
import { CONFIG } from "config/config";
import { useCallback, useEffect, useState } from "react";
import DeleteCategory from "../CategoryDelete/DeleteSubCategory";
import AddSubCategory from "../SubCategoryAdd/AddSubCategory";
import EditSubCategory from "../CategoryEdit/EditSubCategory";
import { Link, useParams } from "react-router-dom";
import AddCategory from "features/Category/CategoryAdd/AddCategory";
import { useDeleteMultipleSubCategoriesMutation, useGetCategoryQuery } from "redux/api/category/category";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import DeleteSubCategoryMultiple from "../SubCategoryDeleteMultiple/DeleteSubCategoryMultiple";
import { useSnackBar } from "hooks/useSnackBar";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { updateCategoryOrder } from "UpdatedFeatures/api";


function SubCategory() {
  const { t } = useTranslation();
  const {id}=useParams();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [orderChanges, setOrderChanges] = useState<{ [key: number]: number }>({});
  
  const [subCategory,setSubCategory]=useState<ISubCategoryData>({
    id: 0,
    title: "",
    image: "",
    created_at: "",
    updated_at: "",
    order: 0,
  })
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    subCategoryData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    refetch,
  } = useSubCategoryList();
  console.log("subCategoryDataInfo",subCategoryData)

  const [parentCategory,setParentCategory]=useState();
  const [openDeleteMultipleModal,setOpenDeleteMultipleModal]=useState(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedCategory, setDeletedCategory] = useState<{
    id: number;
    title: string;
  }>();
 const {
    data: parentCateg,
  } = useGetCategoryQuery(Number(id));
  const openDeleteDialogStatus = useCallback(
    (id: number, title: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCategory({ id: id, title: title });
    },
    [setOpenDeleteDialog, setDeletedCategory]
  );

  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };
  const openDeleteMultipleDialogStatus =() => {
    setOpenDeleteMultipleModal(true);

};
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [deleteCode,{data,error,isLoading:loading,reset}] =
    useDeleteMultipleSubCategoriesMutation();


const deleteMultiple=(event:any)=>{
  event.preventDefault()
  deleteCode({
    categories_ids:selectedItems,
    select_all:selectAll,
    search:search
})
if(!error)
setOpenDeleteMultipleModal(false)
}
if (error) {
  displayErrorApiSnackBar(error);
  reset();
}
if (data) {
  displaySuccessSnackBar(t("category.successfully_deleted"));
  reset();
}
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedCategory, setEditedCategory] = useState<{
    id: number;
    title: string;
    image: string;
  }>();

  const openEditingDialogStatus = useCallback(
    (id: number, title: string, image: string) => () => {
      setEditedCategory({ id, title, image });
      setOpenEditingDialog(true);
    },
    []
  );
  const [checkedItems,setCheckedItems]=useState<number[]>([])
  const handleChangeCheckbox=(event:React.ChangeEvent<HTMLInputElement>,id:number)=>{
    if(event.target.checked){
    setCheckedItems((prevState:number[])=>([...prevState,id]))
    }
    else {
      setCheckedItems((prevState:number[])=>(prevState.filter((item:number)=>(item!==id))))

    }
  }
  useEffect(()=>{
    setParentCategory(parentCateg)
  },[parentCateg])

  const mutation = useMutation<
    { data: any },
    Error,
    { subCategoryId: number; newOrder: number }
  >({
    mutationFn: ({ subCategoryId, newOrder }) => updateCategoryOrder({ categoryId: subCategoryId, newOrder }),
    onSuccess: () => {
      refetch();
      toast.success('La sous-catégorie a été mise à jour avec succès !');
    },
    onError: () => {
      toast.error('Erreur lors de la mise à jour de la sous-catégorie.');
    },
  });

  const handleOrderChange = (subCategoryId: number, newOrder: number) => {
    mutation.mutate({ subCategoryId, newOrder });
  };

  const handleOrderInputChange = (subCategoryId: number, currentOrder: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOrder = parseInt(e.target.value);
    if (newOrder === currentOrder) {
      const newChanges = { ...orderChanges };
      delete newChanges[subCategoryId];
      setOrderChanges(newChanges);
    } else {
      setOrderChanges({
        ...orderChanges,
        [subCategoryId]: newOrder
      });
    }
  };

  return (
    <DashboardLayout
      title={t("category.sub_cat_page_title")}
      selectedPath={RouteIdEnum.SubCategory}
      backPath={RouteIdEnum.Categories}

    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <ButtonContainerStyle item xs={12} sm={4}>
        {((selectAll || selectedItems.length != 0) )&&<DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
       }
         &nbsp;
                   <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[

          {
            key: "checkbox",
            title: <Checkbox  color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "title",
            title: t("category.title"),
            onClick: onOrderChange("title"),
            asc: orderBy === "title" ? orderDir === "asc" : undefined,
          },
          {
            key: "image",
            title: t("category.image"),
          },
          {
            key: "created_at",
            title: t("category.created_at"),
          },
          { key: "rank", title: "Rank" },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={subCategoryData?.data?.map((e: ISubCategoryData) => {
          return {
            checkbox: (
              <Checkbox  checked={(!selectAll && selectedItems.includes(e.id))||(selectAll && !selectedItems.includes(e.id))}
              onChange={() => handleCheckboxChange(e.id)}
              />

            ),
            row:<input id={`${e.id}`} onChange={(event:any)=>handleChangeCheckbox(event,e.id)} type="checkbox" />,
            title: <p>{e.title}</p>,
            image: (
              <p>
                {
                  <CategoryImageStyle
                    src={CONFIG.BASE_URL + e.image}
                  ></CategoryImageStyle>
                }
              </p>
            ),
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            rank: (
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  value={orderChanges[e.id] ?? e.order}
                  onChange={handleOrderInputChange(e.id, e.order)}
                  className="p-2 border max-w-24 w-fit border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400 rounded-md"
                />
                {orderChanges[e.id] !== undefined && (
                  <button
                    onClick={() => {
                      handleOrderChange(e.id, orderChanges[e.id]);
                      const newChanges = { ...orderChanges };
                      delete newChanges[e.id];
                      setOrderChanges(newChanges);
                    }}
                    className="p-1 bg-[#b269ae] text-xs text-white rounded-md"
                  >
                    Sauvegarder
                  </button>
                )}
              </div>
            ),
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(e.id, e.title, e.image)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.title)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: subCategoryData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
       <AddSubCategory
       category={parentCateg}
      openSubCatAddingDialog={openAddingDialog}
      setOpenSubCatAddingDialog={setOpenAddingDialog}
    ></AddSubCategory>
      {deletedCategory && (
        <DeleteCategory
          id={deletedCategory.id}
          title={deletedCategory.title}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteCategory>
      )}
       {editedCategory && (
        <EditSubCategory
        id={id}
          iniData={{
            id: editedCategory.id,
            title: editedCategory.title,
            image: editedCategory.image,
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditSubCategory>
      )}
          {selectedItems && (
        <DeleteSubCategoryMultiple
        isLoading={loading}
          subcategs={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteSubCategoryMultiple>
      )}

    </DashboardLayout>
  );
}
export default SubCategory;
