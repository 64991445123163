import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Puff } from "react-loader-spinner";
import DeletePosConfirmationModal from "./DeletePosConfirmationModal";
import {
  createPointOfSale,
  updatePointOfSale,
  getPointOfSales,
  deletePointOfSale,
  restorePos,
} from "UpdatedFeatures/api";
import AddPointOfSale from "./AddPosModal";
import EditPos from "./EditPos";
import { toast } from "react-toastify";
import { FaPen, FaTrash } from "react-icons/fa";
import Pagination from "UpdatedComponents/Pagination";
import { IoSearch } from "react-icons/io5";
import ImportExport from "components/common/ImportExport";
import TrashedSwitch from "UpdatedComponents/TrashedSwitch";
import { Tooltip } from "@mui/material";
import { MdOutlineRestorePage } from "react-icons/md";
import RestoreModal from "UpdatedFeatures/RestoreModal";

export default function PosTable() {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [search, setSearch] = useState(""); // State for search input
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [posToDelete, setPosToDelete] = useState(null);
  const [isArchived, setIsArchived] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    pays: "",
    code_postal: "",
    contact_number: "",
    latitude: "",
    longitude: "",
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    address: "",
    pays: "",
    code_postal: "",
    contact_number: "",
    latitude: "",
    longitude: "",
  });
  const [pos, setPos] = useState(null);
  const [selectedPos, setSelectedPos] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // Set perPage to a reasonable number
  const queryClient = useQueryClient();

  const { data, error, isLoading, isFetching, isError } = useQuery({
    queryKey: ["pointOfSales", page, perPage, search, isArchived],
    queryFn: () => getPointOfSales(page, perPage, search, isArchived),
    keepPreviousData: true, // Keep previous data while fetching new page
  });
  console.log(data);
  // Ensure totalPages is calculated correctly
  const totalPages = Math.ceil(data?.total / perPage);

  const createMutation = useMutation({
    mutationFn: ({ data }) => createPointOfSale(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["pointOfSales", page, perPage, search]);
      setOpen(false);
      toast.success("Le point de vente a été ajouté !");
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de l'ajout du point de vente : ${error.message}`
      );
      setOpen(false);
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, data }) => updatePointOfSale(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["pointOfSales", page, perPage]);
      setOpenEdit(false);
      toast.success("Le point de vente a été modifié !");
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de la modification du point de vente : ${error.message}`
      );
      setOpenEdit(false);
    },
  });
  const deletePOS = useMutation({
    mutationFn: (posId) => deletePointOfSale(posId),
    onSuccess: () => {
      queryClient.invalidateQueries(["pointOfSales", page, perPage, search]);
      setIsModalOpen(false);
      setPosToDelete(null);
      toast.success("Point de vente supprimé avec succès !");
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de la suppression du point de vente : ${error.message}`
      );
    },
  });
  const handleClick = (person) => {
    setOpenEdit(true);
    setPos(person);
  };
  const openDeleteModal = (person) => {
    setPosToDelete(person);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    if (posToDelete) {
      deletePOS.mutate(posToDelete.id);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPosToDelete(null);
  };
  const handleSelect = (id) => {
    setSelectedPos((prev) => {
      if (prev.includes(id)) {
        return prev.filter((selectedId) => selectedId !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };
  const nextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [posToRestore, setPosToRestore] = useState(null);

  const restoreMutation = useMutation({
    mutationFn: (id) => restorePos(id),
    onSuccess: () => {
      toast.success("pos restauré avec succès !");
      queryClient.invalidateQueries(["pointOfSales", page, perPage, search]);
      setRestoreModalOpen(false);
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de la restauration : ${
          error.response?.data?.error || error.message
        }`
      );
      setRestoreModalOpen(false);
    },
  });
  const handleRestoreClick = (pos) => {
    setPosToRestore(pos);
    setRestoreModalOpen(true);
  };

  const handleRestoreConfirm = () => {
    if (posToRestore) {
      restoreMutation.mutate(posToRestore.id);
    }
  };
  const handleDelete = (id) => {
    deletePOS.mutate(id);
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  if (isError) {
    return <p>Erreur : {error.message}</p>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-12">
      <EditPos
        open={openEdit}
        setOpen={setOpenEdit}
        setFormData={setEditFormData}
        formData={editFormData}
        mutation={editMutation}
        pos={pos}
      />

      <AddPointOfSale
        open={open}
        setOpen={setOpen}
        setFormData={setFormData}
        formData={formData}
        mutation={createMutation}
      />
      <RestoreModal
        open={restoreModalOpen}
        onConfirm={handleRestoreConfirm}
        onCancel={() => setRestoreModalOpen(false)}
        itemTitle={posToRestore?.name}
      />
      <div className="sm:flex sm:items-center justify-between">
        <div className="relative w-1/3">
          <form className="flex items-center">
            <input
              type="text"
              placeholder="Rechercher..."
              className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
              value={search} // Bind search state to input value
              onChange={handleSearchChange} // Update search state on input change
            />
            <button
              type="submit"
              className="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <IoSearch color="purple" />
            </button>
          </form>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 flex items-center gap-3">
          <TrashedSwitch enabled={isArchived} setEnabled={setIsArchived} />
          <ImportExport />
          <button
            type="button"
            className="flex items-center justify-center px-3 py-1 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
            onClick={() => setOpen(true)}
          >
            <span className="text-sm">+ Ajouter</span>
          </button>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-[#b269ae]">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Ville
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Pays
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Code Postal
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Numéro de contact
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Latitude
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      Longitude
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-normal text-gray-100"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Modifier</span>
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <div className="flex justify-center mx-auto">
                    <Puff
                      visible={true}
                      height="50"
                      width="50"
                      color="#895a7c"
                      ariaLabel="puff-loading"
                    />
                  </div>
                ) : (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data?.data.map((person) => (
                      <tr key={person.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {person.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.address}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.pays}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.code_postal}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.contact_number}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.latitude}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.longitude}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.id}
                        </td>
                        {isArchived ? (
                          <Tooltip title={"Restorer"} placement="top">
                            <MdOutlineRestorePage
                              size={30}
                              className="text-[#b369ae] hover:cursor-pointer "
                              onClick={() => handleRestoreClick(person)}
                            />
                          </Tooltip>
                        ) : (
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              className="text-[#b269ae] hover:text-purple-300"
                              onClick={() => handleClick(person)}
                            >
                              <FaPen />
                            </button>
                            <button
                              className="text-[#b269ae] hover:text-purple-300 ml-4"
                              onClick={() => openDeleteModal(person)}
                            >
                              <FaTrash />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                    {isModalOpen && (
                      <DeletePosConfirmationModal
                        open={isModalOpen}
                        onConfirm={confirmDelete}
                        onCancel={closeModal}
                        isLoading={deletePOS.isLoading}
                      />
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={setPage}
        onNextPage={nextPage}
        onPrevPage={prevPage}
        setPage={setPage}
      />
    </div>
  );
}
