import { RouteIdEnum } from 'config/enums'
import DashboardLayout from 'layouts/layouts/DashboardLayout/DashboardLayout'
import TransferTable from './TransferTable'

const Index = () => {
    return (
        <DashboardLayout
            title={"Gestion des Transfer"}
            selectedPath={RouteIdEnum.Transfer}
        >
            <TransferTable />
        </DashboardLayout>
    )
}

export default Index