import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FaPen, FaTrash } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { MdOutlineRestorePage } from "react-icons/md";
import { toast } from "react-toastify";
import Pagination from "UpdatedComponents/Pagination";
import { Puff } from "react-loader-spinner";
import RestoreModal from "UpdatedFeatures/RestoreModal";
import Badge from "UpdatedFeatures/Badge";

// Dummy data for transfers
const dummyTransfers = [
    {
        id: 1,
        reference: "TRF001",
        source: "Stock A",
        destination: "Stock B",
        status: 0,
        createdAt: "2023-10-01",
    },
    {
        id: 2,
        reference: "TRF002",
        source: "Stock C",
        destination: "Stock D",
        status: 1,
        createdAt: "2023-10-02",
    },
    // Add more dummy data as needed
];

export default function TransferTable() {
    const [search, setSearch] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isArchived, setIsArchived] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage] = useState(10);
    const [restoreModalOpen, setRestoreModalOpen] = useState(false);
    const [itemToRestore, setItemToRestore] = useState(null);

    // Simulate fetching data
    const { data: transfers = dummyTransfers, isLoading, isError } = useQuery({
        queryKey: ["transfers", page, perPage, searchTerm, isArchived],
        queryFn: () => Promise.resolve(dummyTransfers), // Replace with actual API call
    });

    const handleSearchChange = (e) => setSearch(e.target.value);
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchTerm(search);
    };

    const handleRestoreClick = (item) => {
        setItemToRestore(item);
        setRestoreModalOpen(true);
    };

    const handleRestoreConfirm = () => {
        // Implement restore logic here
        toast.success("Transfert restauré avec succès !");
        setRestoreModalOpen(false);
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-[400px]">
                <Puff visible={true} height="80" width="80" color="#895a7c" ariaLabel="puff-loading" />
            </div>
        );
    }

    if (isError) {
        return <div>Une erreur s'est produite lors du chargement des données.</div>;
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-12">
            <div className="sm:flex sm:items-center justify-between">
                <div className="relative w-1/3">
                    <form onSubmit={handleSearchSubmit} className="flex items-center">
                        <input
                            type="text"
                            placeholder="Rechercher..."
                            value={search}
                            onChange={handleSearchChange}
                            className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <IoSearch color="purple" />
                        </button>
                    </form>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 flex items-center gap-3">
                    <button
                        onClick={() => {/* Logic to open create transfer modal */ }}
                        className="flex items-center justify-center px-3 py-2 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
                    >
                        <span className="text-sm">+ Créer un transfert</span>
                    </button>
                </div>
            </div>

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-[#b269ae]">
                                    <tr>
                                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6">Référence</th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">Provenance</th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">Destination</th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">Statut</th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">Date de création</th>
                                        <th className="relative py-3.5 pl-3 pr-4 sm:pr-6"><span className="sr-only">Actions</span></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {transfers.map((item) => (
                                        <tr key={item.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{item.reference}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.source}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.destination}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><Badge status={item.status} type={"transfer"} /></td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.createdAt}</td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center justify-end gap-1">
                                                <button
                                                    onClick={() => {/* Logic to open create transfer modal */ }}
                                                    className="flex items-center justify-center px-3 py-1 rounded-full bg-[#b269ae]/20 border border-[#b269ae] text-[#b269ae] font-semibold transition-all duration-300 mr-3"
                                                >
                                                    <span className="text-sm">Valider</span>
                                                </button>
                                                <button className="text-[#b269ae] hover:text-purple-300" onClick={() => {/* Logic to edit transfer */ }}>
                                                    <FaPen />
                                                </button>
                                                <button className="text-[#b269ae] hover:text-purple-300 ml-4" onClick={() => {/* Logic to delete transfer */ }}>
                                                    <FaTrash />
                                                </button>

                                                {/* <button className="text-[#b269ae] hover:text-purple-300 ml-4" onClick={() => handleRestoreClick(item)}>
                          <MdOutlineRestorePage size={30} className="text-[#b269ae] hover:cursor-pointer" />
                        </button> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Pagination
                prevPage={() => setPage((prev) => Math.max(prev - 1, 1))}
                nextPage={() => setPage((prev) => Math.min(prev + 1, Math.ceil(transfers.length / perPage)))}
                totalPages={Math.ceil(transfers.length / perPage)}
                setPage={setPage}
            />

            <RestoreModal
                open={restoreModalOpen}
                onConfirm={handleRestoreConfirm}
                onCancel={() => setRestoreModalOpen(false)}
                itemTitle={itemToRestore?.reference}
            />
        </div>
    );
}