import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IBrandData } from "./Brand.types";
import { Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
  CategoryImageStyle,
} from "./Brand.style";
import { Add, Delete, Edit } from "@mui/icons-material";
import useBrandList from "./useBrandList";
import { CONFIG } from "config/config";
import { useCallback, useState } from "react";
import DeleteCategory from "../BrandDelete/DeleteBrand";
import AddCategory from "../BrandAdd/AddBrand";
import EditCategory from "../BrandEdit/EditBrand";
import { Link } from "react-router-dom";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import AddSubCategory from "features/SubCategory/SubCategoryAdd/AddSubCategory";

import DeleteCategoryMultiple from "../BrandDeleteMultiple/DeleteBrandMultiple";
import { useSnackBar } from "hooks/useSnackBar";
import DeleteBrand from "../BrandDelete/DeleteBrand";
import AddBrand from "../BrandAdd/AddBrand";
import DeleteBrandMultiple from "../BrandDeleteMultiple/DeleteBrandMultiple";
import EditBrand from "../BrandEdit/EditBrand";
import TrashedSwitch from "UpdatedComponents/TrashedSwitch";
import { MdOutlineRestorePage } from "react-icons/md";
import RestoreModal from "UpdatedFeatures/RestoreModal";
import { restoreBrand } from "UpdatedFeatures/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useDeleteMultipleBrandsMutation } from "redux/api/brand/brand";


function Brand() {
  const { t } = useTranslation();
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    brandData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    isArchived,
    setIsArchived,
    refetch
  } = useBrandList();
  const [brand, setBrand] = useState<IBrandData>({
    id: 0,
    name: "",
    image: "",
    created_at: "",
    updated_at: ""
  })
  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedCategory, setDeletedCategory] = useState<{
    id: number;
    name: string;
  }>();
  const openDeleteMultipleDialogStatus = () => {
    setOpenDeleteMultipleModal(true);

  };
  const [isAddSubCat, setIsAddSubCat] = useState(false)

  const openDeleteDialogStatus = useCallback(
    (id: number, name: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCategory({ id: id, name: name });
    },
    [setOpenDeleteDialog, setDeletedCategory]
  );
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [deleteCode, { data, error, reset, isLoading: loading }] =
    useDeleteMultipleBrandsMutation();


  const deleteMultiple = (event: any) => {
    event.preventDefault()
    deleteCode({
      brands_ids: selectedItems,
      select_all: selectAll,
      search: search
    })
    if (!error)
      setOpenDeleteMultipleModal(false)

  }

  const [openSubCatAddingDialog, setSubCatOpenAddingDialog] = useState<boolean>(false);

  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setIsAddSubCat(false)

    setOpenAddingDialog(true);
  }, []);
  const openAddingSubCatDialogStatus = useCallback((brand: IBrandData) => {
    setIsAddSubCat(true)

    setSubCatOpenAddingDialog(true);
    setBrand({ ...brand })
  }, []);
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedBrand, setEditedBrand] = useState<{
    id: number;
    name: string;
    image: string;
  }>();

  const restoreMutation = useMutation({
    mutationFn: (id: number) => restoreBrand(id),
    onSuccess: () => {
      toast.success(t("brand.restore_success"));
      refetch()
      setRestoreModalOpen(false);
    },
    onError: (error) => {
      toast.error(t("brand.restore_error"));
      setRestoreModalOpen(false);
    },
  });

  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [brandToRestore, setBrandToRestore] = useState<{ id: number; name: string } | null>(null);

  const handleRestoreClick = (brand: IBrandData) => {
    setBrandToRestore({ id: brand.id, name: brand.name });
    setRestoreModalOpen(true);
  };

  const handleRestoreConfirm = () => {
    if (brandToRestore) {
      restoreMutation.mutate(brandToRestore.id);
    }
  };

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("brand.successfully_deleted"));
    reset();

  }
  const openEditingDialogStatus = useCallback(
    (id: number, name: string, image: string) => () => {
      setEditedBrand({ id, name, image });
      setOpenEditingDialog(true);
    },
    []
  );

  return (
    <DashboardLayout
      title={t("brand.page_title")}
      selectedPath={RouteIdEnum.Brand}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex  items-center gap-5">
          {((selectAll || selectedItems.length != 0)) && <DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
          }
          &nbsp;
          <TrashedSwitch enabled={isArchived} setEnabled={setIsArchived} />
          <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </div>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "name",
            title: t("brand.name"),
            onClick: onOrderChange("name"),
            asc: orderBy === "name" ? orderDir === "asc" : undefined,
          },
          {
            key: "image",
            title: t("brand.image"),
          },
          {
            key: "created_at",
            title: t("brand.created_at"),
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={brandData?.data.map((e: IBrandData) => {
          return {
            checkbox: (
              <Checkbox checked={(!selectAll && selectedItems.includes(e.id)) || (selectAll && !selectedItems.includes(e.id))}
                onChange={() => handleCheckboxChange(e.id)}
              />

            ),
            name: <p>{e.name}</p>,
            image: (
              <p>
                {
                  <CategoryImageStyle
                    src={CONFIG.BASE_URL + e.image}
                  ></CategoryImageStyle>
                }
              </p>
            ),
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            action: (

              isArchived ? (
                <div>
                  <MdOutlineRestorePage
                    size={30}
                    className="text-[#b369ae] hover:cursor-pointer"
                    onClick={() => handleRestoreClick(e)}
                  />
                </div>
              ) :
                (
                  <ActionContainerStyle>
                    {
                      <Tooltip title={t("common.edit")}>
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={openEditingDialogStatus(e.id, e.name, e.image)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    }
                    {
                      <Tooltip title={t("common.delete")}>
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={openDeleteDialogStatus(e.id, e.name)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    }

                  </ActionContainerStyle>
                )

            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: brandData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {deletedCategory && (
        <DeleteBrand
          id={deletedCategory.id}
          name={deletedCategory.name}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteBrand>
      )}
      {
        <AddBrand
          openAddingDialog={openAddingDialog}
          setOpenAddingDialog={setOpenAddingDialog}
        ></AddBrand>
      }
      {selectedItems && (
        <DeleteBrandMultiple
          isLoading={loading}
          catesgs={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteBrandMultiple>
      )}
      {editedBrand && (
        <EditBrand
          iniData={{
            id: editedBrand.id,
            name: editedBrand.name,
            image: editedBrand.image,
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditBrand>
      )}
      {brandToRestore && (
        <RestoreModal
          open={restoreModalOpen}
          onConfirm={handleRestoreConfirm}
          onCancel={() => setRestoreModalOpen(false)}
          itemTitle={brandToRestore.name}
        />
      )}
    </DashboardLayout>
  );
}
export default Brand;
