import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StatusFilter({ statusOptions, selected, setSelected, STATUS }) {


    const getStatusBadge = (status) => {
        // Handle badge differently for "All" option
        if (status === 5) {
            return null; // No badge for the "All" option
        }

        let text = '';
        let bgColor, textColor, ringColor;

        switch (status) {
            case STATUS.Pending:
                text = statusOptions[status].label;
                bgColor = 'bg-yellow-500/10';
                textColor = 'text-yellow-400';
                ringColor = 'ring-yellow-500/20';
                break;
            case STATUS.Approved:
                text = statusOptions[status].label;
                bgColor = 'bg-blue-500/10';
                textColor = 'text-blue-400';
                ringColor = 'ring-blue-500/20';
                break;
            case STATUS.Active:
                text = statusOptions[status].label;
                bgColor = 'bg-green-500/10';
                textColor = 'text-green-400';
                ringColor = 'ring-green-500/20';
                break;

            default:
                text = 'All';
                bgColor = 'bg-gray-500/10';
                textColor = 'text-gray-400';
                ringColor = 'ring-gray-500/20';
                break;
        }

        return (
            <span className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}`}>
                {text}
            </span>
        );
    }

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <div className="relative">
                    <Listbox.Button className="relative border border-[#b269ae] w-full min-w-40 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                            <span className="ml-3 block truncate">{selected.label}</span>
                            <div className="ml-2">
                                {getStatusBadge(selected.id)}
                            </div>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white border border-[#b269ae] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {statusOptions.map((status) => (
                                <Listbox.Option
                                    key={status.id}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-[#b269ae] text-white' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                    }
                                    value={status}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <div className="flex items-center">
                                                {status.id !== 5 && (
                                                    <span
                                                        className={classNames(
                                                            status.id === STATUS.Pending ? 'bg-yellow-400' :
                                                                status.id === STATUS.Approved ? 'bg-blue-400' :
                                                                    status.id === STATUS.Active ? 'bg-green-400' :
                                                                        'bg-gray-200',
                                                            'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                )}
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                                    {status.label}
                                                </span>
                                            </div>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        active ? 'text-white' : 'text-green-700',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}
