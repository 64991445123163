import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
} from "./ExpeditionCategories.style";
import { Delete, Edit } from "@mui/icons-material";
import { useCallback, useState } from "react";
import DeleteExpeditionCategory from "../ExpeditionCategoryDelete/DeleteExpeditionCategory";
import AddExpeditionCategory from "../ExpeditionCategoryAdd/AddExpeditionCategory";
import EditExpeditionCategory from "../ExpeditionCategoryEdit/EditExpeditionCategory";
import { IExpeditionCategoriesData } from "./ExpeditionCategories.types"
import useExpeditionCategoriesList from "./useExpeditionCategoriesList";
import { Checkbox } from "@mui/material";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import { useSnackBar } from "hooks/useSnackBar";
import { useDeleteMultipleSubCategoriesMutation } from "redux/api/category/category";
import DeleteCategoryMultiple from "../ExpeditionCategoryMultipleDelete/DeleteExpeditionCategoryMultiple";
import DeleteExpeditionCategoryMultiple from "../ExpeditionCategoryMultipleDelete/DeleteExpeditionCategoryMultiple";
import { useDeleteMultipleExpeditonCategsMutation } from "redux/api/delivery/expeditionCategs/expeditionCategs";
import TrashedSwitch from "UpdatedComponents/TrashedSwitch";
import { MdOutlineRestorePage } from "react-icons/md";
import { useMutation } from "@tanstack/react-query";
import { restoreShippingCateg } from "UpdatedFeatures/api";
import { toast } from "react-toastify";
import RestoreModal from "UpdatedFeatures/RestoreModal";

function ExpeditionCategs() {
  const { t } = useTranslation();
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    catgorievalues,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    isArchived,
    setIsArchived,
    refetch
  } = useExpeditionCategoriesList();
  const [tax, setTax] = useState<IExpeditionCategoriesData>({
    id: 0,
    name: "",
    created_at: "",
    updated_at: ""
  })

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedTax, setDeletedTax] = useState<{
    id: number;
    name: string;
  }>();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false)
  const openDeleteDialogStatus = useCallback(
    (id: number, name: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedTax({ id: id, name: name });
    },
    [setOpenDeleteDialog, setDeletedTax]
  );
  const openDeleteMultipleDialogStatus = () => {
    setOpenDeleteMultipleModal(true);

  };
  const [deleteCode, { data, error, isLoading: loading, reset }] = useDeleteMultipleExpeditonCategsMutation()
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("delivey.successfully_deleted"));
    reset();

  }
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);
  const openAddingSubCatDialogStatus = useCallback((tax: IExpeditionCategoriesData) => {
    setOpenAddingDialog(true);
    setTax({ ...tax })
  }, []);
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedTax, setEditedTax] = useState<{
    id: number;
    name: string;
  }>();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };

  const deleteMultiple = (event: any) => {
    event.preventDefault()
    deleteCode({
      categ_ids: selectedItems,
      select_all: selectAll,
      search: search
    })
    if (!error)
      setOpenDeleteMultipleModal(false)
  }
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const openEditingDialogStatus = useCallback(
    (id: number, name: string) => () => {
      setEditedTax({ id, name });
      setOpenEditingDialog(true);
    },
    []
  );
  const restoreMutation = useMutation({
    mutationFn: (id: number) => restoreShippingCateg(id),
    onSuccess: () => {
      toast.success(t("Category d'expedition a été restaurée avec succès !"));
      refetch()
      setRestoreModalOpen(false);
    },
    onError: (error) => {
      toast.error(t("Erreur lors de la restauration du Category d'expedition"));
      setRestoreModalOpen(false);
    },
  });
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [catToRestore, setCatToRestore] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const handleRestoreClick = (cat: IExpeditionCategoriesData) => {
    setCatToRestore({ id: cat.id, name: cat.name });
    setRestoreModalOpen(true);
  };

  const handleRestoreConfirm = () => {
    if (catToRestore) {
      restoreMutation.mutate(catToRestore.id);
    }
  };
  const [checkedItems, setCheckedItems] = useState<number[]>([])
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (event.target.checked) {
      setCheckedItems((prevState: number[]) => ([...prevState, id]))
    }
    else {
      setCheckedItems((prevState: number[]) => (prevState.filter((item: number) => (item !== id))))

    }
  }
  return (
    <DashboardLayout
      title={t("delivery.categories")}
      selectedPath={RouteIdEnum.Expedition_Categories}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex  items-center gap-5">
          {((selectAll || selectedItems.length != 0)) && <DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
          }
          <TrashedSwitch enabled={isArchived} setEnabled={setIsArchived} />
          &nbsp;
          <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </div>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "name",
            title: t("tax.name"),
            onClick: onOrderChange("name"),
            asc: orderBy === "name" ? orderDir === "asc" : undefined,
          },

          {
            key: "created_at",
            title: t("category.created_at"),
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={catgorievalues?.data.map((e: IExpeditionCategoriesData) => {
          return {
            checkbox: (
              <Checkbox checked={(!selectAll && selectedItems.includes(e.id)) || (selectAll && !selectedItems.includes(e.id))}
                onChange={() => handleCheckboxChange(e.id)}
              />

            ),
            name: <p> {e.name}</p>,
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            action: (
              isArchived ? (
                <Tooltip title={"Restorer"} placement="top">
                  <MdOutlineRestorePage size={30} className="text-[#b369ae] hover:cursor-pointer " onClick={() => handleRestoreClick(e)}/>
                </Tooltip>
              ) :
                (
                  <ActionContainerStyle>
                    {
                      <Tooltip title={t("common.edit")}>
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={openEditingDialogStatus(e.id, e.name)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    }
                    {
                      <Tooltip title={t("common.delete")}>
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={openDeleteDialogStatus(e.id, e.name)}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    }

                  </ActionContainerStyle>
                )
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: catgorievalues?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {deletedTax && (
        <DeleteExpeditionCategory
          id={deletedTax.id}
          name={deletedTax.name}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteExpeditionCategory>
      )}
      <AddExpeditionCategory
        openAddingDialog={openAddingDialog}
        setOpenAddingDialog={setOpenAddingDialog}
      ></AddExpeditionCategory>

      {selectedItems && (
        <DeleteExpeditionCategoryMultiple
          isLoading={loading}
          exped_categs={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteExpeditionCategoryMultiple>
      )}
      {editedTax && (
        <EditExpeditionCategory
          iniData={{
            id: editedTax.id,
            name: editedTax.name,
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditExpeditionCategory>
      )}
       {catToRestore && (
              <RestoreModal
                open={restoreModalOpen}
                onConfirm={handleRestoreConfirm}
                onCancel={() => setRestoreModalOpen(false)}
                itemTitle={catToRestore.name}
              />
            )}
    </DashboardLayout>
  );
}
export default ExpeditionCategs;