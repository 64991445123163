import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";
import { IPointOfSale } from "features/Product/ProductList/Product.types";


export const productApi = createApi({
  reducerPath: "ProductApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Product", "EditProduct"],
  endpoints: (builder) => ({
    getAllProduct: builder.query({
      query: (params: { paginator?: Paginator, all?: boolean, category?: any, Subcategory?: any, Stock?: any, Garantie?: any, DLC?: boolean, Status?: any, POS?: IPointOfSale, withTrashed?: number }) => {
        return injectParamsPaginator(ENDPOINTS.PRODUCT, params.paginator, params.all, params.withTrashed, true, params.category, params.Subcategory, params.Stock, params.Garantie, params.DLC, params.Status, params.POS);
      },
      providesTags: ["Product"],
    }),
    getProduct: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/${id}`,
        method: "GET",
      }),
      providesTags: ["EditProduct"],
    }),
    deleteProduct: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
    dupliqueProduct: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/duplicate/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Product"],
    }),
    deleteMultipleProduct: builder.mutation({
      query: (params: {
        product_ids: number[],
        select_all: boolean,
        search: string,
        status: number | null,
        garantie: string,
        category: string,
        subCategory_id: number,
        date_limite_de_consommation: string,
        selectedPOS: string,
        selectedStatus: string
      }) => ({
        url: `${ENDPOINTS.PRODUCT}/delete-multiple`,
        method: "POST",
        body: {
          product_ids: params.product_ids,
          select_all: params.select_all,
          search: params.search,
          status: params.status,
          garantie: params.garantie,
          category: params.category,
          subCategory_id: params.subCategory_id,
          date_limite_de_consommation: params.date_limite_de_consommation,
          selectedPOS: params.selectedPOS,
          selectedStatus: params.selectedStatus,
        },
      }),
      invalidatesTags: ["Product"],
    }),
    invalidateProductCache: builder.mutation({
      queryFn: () => {
        return { data: null };
      },
      invalidatesTags: ["EditProduct"],
    }),
    GetPublishDraftProduct: builder.mutation({
      query: (params: {
        product_ids: number[], select_all: boolean, search: string, status: number, garantie: string,
        category: string,
        subCategory_id: number,
        date_limite_de_consommation: string,
        selectedPOS: string,
        selectedStatus: string
      }) => ({
        url: `${ENDPOINTS.PRODUCT}/change-status-multiple`,
        method: "POST",
        body: {
          product_ids: params.product_ids,
          select_all: params.select_all,
          search: params.search,
          status: params.status,
          garantie: params.garantie,
          category: params.category,
          subCategory_id: params.subCategory_id,
          date_limite_de_consommation: params.date_limite_de_consommation,
          selectedPOS: params.selectedPOS,
          selectedStatus: params.selectedStatus,
        },
      }),
      invalidatesTags: ["Product", "EditProduct"],
    }),
    addProduct: builder.mutation({
      query({
        title,
        description,
        price,
        priceLoloTtc,
        priceNonSubscriber,
        priceLolo,
        priceNonSubscriberTtc,
        unite_format,
        quantity,
        quantities,
        category_product_id,
        images,
        keyWords,
        purchase_price,
        Subcategory_product_id,
        tax_id,
        discount,
        price_ttc,
        barcode,
        label,
        status,
        publication_date,
        display,
        nutri_score,
        stock_alert,
        min_qty,
        marge,
        categ_exped,
        nutritional_values,
        provider,
        format,
        brand,
        garentie,
        dateLimiteCons,
        DateDurabMin,
        frais_stock
      }) {
        const formData = new FormData();
        console.log(quantities)
        formData.append("title", title);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("priceLolo", priceLolo); // Added priceLolo
        formData.append("priceLoloTtc", priceLoloTtc); // Added priceLoloTtc
        formData.append("priceNonSubscriber", priceNonSubscriber); // Added priceNonSubscriber
        formData.append("priceNonSubscriberTtc", priceNonSubscriberTtc); // Added priceNonSubscriberTtc
        formData.append("unite_format", unite_format); // Added unite_format
        formData.append("quantity", quantity);
        Object.keys(quantities).forEach((key: string, index: number) => {
          formData.append(`quantities[${index}][point_of_sales_id]`, key); // Point of sales ID (key)
          formData.append(`quantities[${index}][quantity]`, quantities[key]); // Corresponding quantity value
        });
        formData.append("category_product_id", category_product_id);
        formData.append("purchase_price", purchase_price);
        formData.append("Subcategory_product_id", Subcategory_product_id);
        formData.append("tax_id", tax_id);
        formData.append("discount", discount);
        formData.append("price_ttc", price_ttc);
        formData.append("barcode", barcode);
        formData.append("label", label);
        formData.append("status", status);
        formData.append("publication_date", publication_date);
        formData.append("display", JSON.stringify(display));
        formData.append("nutri_score", nutri_score);
        formData.append("stock_alert", stock_alert);
        formData.append("min_qty", min_qty);
        formData.append("marge", marge);
        formData.append("format", format);
        formData.append("categ_exped", categ_exped);

        // Nutritional values
        nutritional_values.forEach((nutritional_value: any, index: number) => {
          const nutritionalname = nutritional_value.name;
          const nutritionalvalue = nutritional_value.value;

          formData.append("nutritional_values[" + index + "][name]", nutritionalname);
          formData.append("nutritional_values[" + index + "][value]", nutritionalvalue);
        });

        // Keywords
        keyWords.forEach((keyWord: any, index: number) => {
          formData.append("keyWords[" + index + "]", keyWord);
        });

        formData.append("provider", provider);
        formData.append("brand", brand);
        formData.append("garentie", garentie);
        formData.append("dateLimiteCons", dateLimiteCons);
        formData.append("DateDurabMin", DateDurabMin);
        formData.append("frais_stock", frais_stock);
        // Handle file uploads for the images field
        images.forEach((image: any, index: number) => {
          const file = image.file;
          const isMainImage = image.is_main_image;
          const colorHex = image.color_hex;
          const colorName = image.color_name;

          formData.append("images[" + index + "][file]", file);
          formData.append("images[" + index + "][is_main_image]", isMainImage);
          formData.append("images[" + index + "][color_hex]", colorHex);
          formData.append("images[" + index + "][color_name]", colorName);
        });

        return {
          url: `${ENDPOINTS.PRODUCT}`,
          method: "POST",
          credentials: "include",
          headers: {
            // If you need to set specific headers for FormData, add them here
            "enctype": "multipart/form-data"
          },
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),
    getProductsByCategory: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.PRODUCT}/by-category/${id}`,
        method: "GET",
      }),
    }),
    updateProduct: builder.mutation({
      query({ id, formData }) {
        console.log(formData)
        return {
          url: `${ENDPOINTS.PRODUCT}/${id}`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),
    importProduct: builder.mutation({
      query({ formData }) {
        console.log("formData", formData)
        return {
          url: `${ENDPOINTS.PRODUCT}/import`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),

  }),

});

export const {
  useGetAllProductQuery,
  useGetProductQuery,
  useAddProductMutation,
  useDeleteMultipleProductMutation,
  useInvalidateProductCacheMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useDupliqueProductMutation,
  useImportProductMutation,
  useGetProductsByCategoryQuery,
  useGetPublishDraftProductMutation
} = productApi;
