import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { IExpeditionModeAddingData } from "features/ExpeditionMode/ExpeditionModeAdd/AddExpeditionMode.types";
import { baseQueryWithReauth } from "redux/api/rtkBaseQuery";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";

export const expeditionModeiApi = createApi({
    reducerPath: "ExpeditionModeApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Modes"],
    endpoints: (builder) => ({
      getAllExpeditionMode: builder.query({
        query: (params: { paginator?: Paginator,all?:boolean; withTrashed?: number }) => {
          return injectParamsPaginator(ENDPOINTS.EXPEDITION_MODE, params.paginator,undefined,
            params.withTrashed,params.all,);
        },
        providesTags: ["Modes"],

      }),
      getExpeditionModeById:builder.query({
        query: (params: { paginator?: Paginator,id:number }) => {
          return injectParamsPaginator(`${ENDPOINTS.EXPEDITION_MODE}/${params.id}`, params.paginator);
        },
        providesTags: ["Modes"],
      
        }),      
      deleteExpeditionMode:builder.mutation({
        query:(id:number)=>({
            url:`${ENDPOINTS.EXPEDITION_MODE}/${id}`,
        method:"DELETE"
    }),
    invalidatesTags: ["Modes"],

}),
    addExpeditionMode:builder.mutation({
        query:({title,
            start,
            end,
            tax_value,
            type,
            categ_value

        }:IExpeditionModeAddingData)=>({
            url:`${ENDPOINTS.EXPEDITION_MODE}`,
            method:"POST",
            body:{
            title,
            start,
            end,
            tax_value,
            type,
            categ_value
            }
        }
        ),
        invalidatesTags: ["Modes"],


    }),
    updateExpeditionMode: builder.mutation({
        query: ({
            id,
            title,
            start,
            end,
            tax_value,
            type,
            categ_value
        }: IExpeditionModeAddingData) => ({
          url: `${ENDPOINTS.EXPEDITION_MODE}/${id}`,
          method: "POST",
          body: { title,
            start,
            end,
            tax_value,
            type,
            categ_value },
        }),
        invalidatesTags: ["Modes"],

      }),
      deleteMultipleExpeditonModes: builder.mutation({
        query: (params: { mode_ids: number[], select_all: boolean ,search:string}) => ({
          url: `${ENDPOINTS.EXPEDITION_MODE}/delete-multiple`,
          method: "POST",
          body: {
            mode_ids: params.mode_ids,
            select_all: params.select_all,
            search:params.search
          },
        }),
        invalidatesTags:["Modes"]
      }),
      addExpedModeValueToCategExped:builder.mutation({
        query: (params: { id: number, title: string ,mode_value:{mode:string,value:string}[]}) => ({
          url: `shipping_categories/update_Default_Values`,
          method: "POST",
          body: {
            id: params.id,
            title: params.title,
            mode_value:params.mode_value
          },
        }),
      }),
      getCategoriesValuesExpeditionMode:builder.query({
        query: (params: { paginator?: Paginator,id:number }) => {
          return injectParamsPaginator(`${ENDPOINTS.EXPEDITION_MODE}/categories/${params.id}`, params.paginator);
        },
        providesTags: ["Modes"],
      }),
        })
        
        ,
      })
  
  
  export const {useGetCategoriesValuesExpeditionModeQuery,useDeleteMultipleExpeditonModesMutation, useGetAllExpeditionModeQuery,useGetExpeditionModeByIdQuery,useDeleteExpeditionModeMutation,useUpdateExpeditionModeMutation,useAddExpeditionModeMutation ,useAddExpedModeValueToCategExpedMutation} = expeditionModeiApi;
  