import apiClient from "./apiClient";

// Function to create a new Point of Sale
export const createPointOfSale = async (data) => {
  try {
    const response = await apiClient.post("/point-of-sale/create", data);
    return response.data;
  } catch (error) {
    console.error("Error creating Point of Sale:", error);
    throw error;
  }
};

export const getPointOfSales = async (page, perPage, search, isArchived) => {
  try {
    const response = await apiClient.get("/point-of-sale", {
      params: {
        page,
        perPage,
        search,
        withTrashed: isArchived ? 2 : null
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error Getting Point of Sale:", error);
    throw error;
  }
};

export const deletePointOfSale = async (point_of_sale_id) => {
  try {
    const response = await apiClient.delete(`/point-of-sale/${point_of_sale_id}`);
    return response.data;
  } catch (error) {
    console.error('Error Deleting Point of Sale:', error);
    throw error;
  }
};


export const getAllPointOfSales = async () => {
  try {
    const response = await apiClient.get("/basket/get-points-of-sale");
    console.log("api response", response);
    return response.data;
  } catch (error) {
    console.error("Error Getting Point of Sale:", error);
    throw error;
  }
};

export const updatePointOfSale = async (id, data) => {
  try {
    const response = await apiClient.put(`/point-of-sale/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating Point of Sale:", error);
    throw error;
  }
};

export const getSellers = async (page, perPage, search, currentTab, isArchived) => {
  try {
    const response = await apiClient.get("/sellers", {
      params: {
        role: currentTab,
        page,
        perPage,
        search,
        withTrashed: isArchived ? 2 : null
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error Getting Sellers List:", error);
    throw error;
  }
};

export const createSeller = async (data) => {
  try {
    const response = await apiClient.post("/sellers/create", data);
    return response.data;
  } catch (error) {
    console.error("Error creating Seller:", error);
    throw error;
  }
};

export const updateSeller = async (seller, data) => {
  console.log(data);
  try {
    const response = await apiClient.put(`/sellers/update/${seller}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating Seller:", error);
    throw error;
  }
};

export const enableDisableClient = async (user) => {
  try {
    const response = await apiClient.post(
      `/clients/enable-disable/${user}`,
      {}
    ); // Empty body if not required
    return response.data;
  } catch (error) {
    console.error("Error disabling or enabling Client:", error);
    throw error;
  }
};

export const getOrderHistories = async (
  page,
  perPage,
  search,
  type,
  status,
  isArchived
) => {
  try {
    const response = await apiClient.get("/order-histories", {
      params: {
        page,
        perPage,
        search,
        type,
        status,
        withTrashed: isArchived ? 2 : null
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching order histories:", error);
    throw error;
  }
};

export const generateLoginToken = async (clientId) => {
  try {
    const response = await apiClient.get(
      `/clients/generate-login-token/${clientId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error generating login token:", error);
    throw error;
  }
};
// Function to change the status of an order
export const changeOrderStatus = async (order_id, status) => {
  try {
    const response = await apiClient.post("/order-histories/change-status", {
      order_id,
      status,
    });
    return response.data;
  } catch (error) {
    console.error("Error changing order status:", error);
    throw error;
  }
};
// Function to change the status of an order
export const getFormatUnit = async () => {
  try {
    const response = await apiClient.get("/Format-type");
    return response.data;
  } catch (error) {
    console.error("Error getting format unit:", error);
    throw error;
  }
};
export const addFormatUnit = async (name) => {
  try {
    const response = await apiClient.post("/Format-type", {
      name,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de ajout", error);
    throw error;
  }
};

export const getProducts = async (
  search,
  status,
  garantie,
  category,
  subCategory_id,
  date_limite_de_consommation,
  selectedPOS,
  selectedStatus,
  selectedItems,
  selectAll,
  withTrashed
) => {
  const params = {
    search,
    status,
    garantie,
    category,
    subCategory_id,
    date_limite_de_consommation,
    selectedPOS,
    selectedStatus,
    selectedItems,
    selectAll,
    ...(withTrashed && { withTrashed: withTrashed }),
  };

  try {
    const response = await apiClient.get("/products", { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const applyTax = async ({
  taxId,
  productsIds,
  selectAll,
  search,
  status,
  garantie,
  category,
  subCategory_id,
  date_limite_de_consommation,
  selectedPOS,
  selectedStatus,
}) => {
  console.log(search);
  try {
    const response = await apiClient.post("/products/apply-tax-multiple", {
      taxId,
      productsIds,
      selectAll,
      search,
      status,
      garantie,
      category,
      subCategory_id,
      date_limite_de_consommation,
      selectedPOS,
      selectedStatus,
    });
    return response.data;
  } catch (error) {
    console.error("Error applying tax:", error);
    throw error;
  }
};

export const applyCE = async ({
  CEId,
  productsIds,
  selectAll,
  search,
  status,
  garantie,
  category,
  subCategory_id,
  date_limite_de_consommation,
  selectedPOS,
  selectedStatus,
}) => {
  try {
    const response = await apiClient.post("/products/apply-ce-multiple", {
      CEId,
      productsIds,
      selectAll,
      search,
      status,
      garantie,
      category,
      subCategory_id,
      date_limite_de_consommation,
      selectedPOS,
      selectedStatus,
    });
    return response.data;
  } catch (error) {
    console.error("Error applying Category Expédition:", error);
    throw error;
  }
};

export const getStockPlus = async (page, perPage, search, isArchived) => {
  try {
    const response = await apiClient.get("/frais_stock_plus", {
      params: {
        page,
        perPage,
        search,
        withTrashed: isArchived ? 2 : null
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Stock Plus Data:", error);
    throw error;
  }
};
export const CreateStockPlus = async (data) => {
  console.log(data);

  try {
    const response = await apiClient.post("/frais_stock_plus", data.data);
    return response.data;
  } catch (error) {
    console.error("Error creating Stock plus:", error);
    throw error;
  }
};

export const EditStockPlus = async (data) => {
  console.log(data);

  try {
    const response = await apiClient.post(`/frais_stock_plus/${data.data.id}`, {
      name: data.data.name,
      fee: data.data.fee,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating Stock Plus:", error);
    throw error;
  }
};

export const applyStock = async ({
  StockId,
  productsIds,
  selectAll,
  search,
  status,
  garantie,
  category,
  subCategory_id,
  date_limite_de_consommation,
  selectedPOS,
  selectedStatus,
}) => {
  console.log(StockId);
  try {
    const response = await apiClient.post("/products/apply-stock-multiple", {
      StockId,
      productsIds,
      selectAll,
      search,
      status,
      garantie,
      category,
      subCategory_id,
      date_limite_de_consommation,
      selectedPOS,
      selectedStatus,
    });
    return response.data;
  } catch (error) {
    console.error("Error applying Stock Plus:", error);
    throw error;
  }
};
export const getRoles = async () => {
  try {
    const response = await apiClient.get("/roles/all");
    return response.data;
  } catch (error) {
    console.error("Error fetching Roles:", error);
    throw error;
  }
};
export const PaginateRoles = async (page, perPage, search, isArchived) => {
  try {
    const response = await apiClient.get("/roles", {
      params: {
        page,
        perPage,
        search,
        withTrashed: isArchived ? 2 : null
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching Roles:", error);
    throw error;
  }
};

export const CreateRole = async (data) => {
  console.log(data);

  try {
    const response = await apiClient.post("/roles/create", data.data);
    return response.data;
  } catch (error) {
    console.error("Error creating Role:", error);
    throw error;
  }
};

export const updateRole = async (ID, data) => {
  console.log(data);
  try {
    const response = await apiClient.post(`/roles/${ID}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating Role:", error);
    throw error;
  }
};
export const addClient = async (userData) => {
  console.log(userData);
  const response = await apiClient.post("/auth/register", userData);

  return response.data;
};
export const getSubscribers = async () => {
  try {
    const response = await apiClient.get("/clients/subscribed");
    return response.data;
  } catch (error) {
    console.error("Error fetching Roles:", error);
    throw error;
  }
};
export const getNonSubscribers = async () => {
  try {
    const response = await apiClient.get("/clients/non-subscribed");
    return response.data;
  } catch (error) {
    console.error("Error fetching Roles:", error);
    throw error;
  }
};
export const deleteUser = async (user) => {
  try {
    const response = await apiClient.delete(`/auth/${user}`);
    return response.data;
  } catch (error) {
    console.error('Error Deleting user:', error);
    throw error;
  }
};
export const deleteRole = async (role) => {
  try {
    const response = await apiClient.delete(`/roles/${role}`);
    return response.data;
  } catch (error) {
    console.error('Error Deleting role:', error);
    throw error;
  }
};

export const updateCategoryOrder = async ({ categoryId, newOrder }) => {
  try {
    const response = await apiClient.patch(`/category/${categoryId}/order`, {
      new_order: newOrder,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating category order:', error);
    throw error;
  }
};
export const GetProductsBySupplier = async (id) => {
  try {
    const response = await apiClient.get(`/products/get-products-by-supplier/${id}`);
    return response.data;
  } catch (error) {
    console.log(
      `Error retrieving Products with Supplier ${id}:`,
      error.response ? error.response.data : error.message
    );
    throw new Error(`Failed to fetch Products with Supplier ${id}`);
  }
};
export const getProviders = async () => {
  try {
    const response = await apiClient.get("/providers/all");
    return response.data;
  } catch (error) {
    console.error("Error fetching Roles:", error);
    throw error;
  }
};

export const getCategories = async ({ search, page, perPage, orderBy, orderDir }) => {
  try {
    const response = await apiClient.get("/category", {
      params: {
        search,
        page,
        perPage,
        orderBy,
        orderDir
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};
export const GetProductBarcodes = async (id) => {
  try {
    const response = await apiClient.get(`/products/barcodes/${id}`);
    return response.data;
  } catch (error) {
    console.log(
      `Error retrieving product s barcodes ${id}:`,
      error.response ? error.response.data : error.message
    );
    throw new Error(`Failed to fetch product s barcodes ${id}`);
  }
};

export const createArrivage = async (data) => {
  try {
    const response = await apiClient.post("/products/arrivage", data);
    return response.data;
  } catch (error) {
    console.error("Error creating Arrivage:", error);
    throw error;
  }
};
export const getArrivage = async (page = 1, perPage = 10, search = '', isArchived = false, status) => {
  console.log(status)
  try {
    const response = await apiClient.get("/products/get-arrivage", {
      params: {
        page,
        perPage,
        search: search || '',
        withTrashed: isArchived ? 2 : undefined,
        status
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error Getting Arrivage:", error);
    throw error;
  }
};

export const deleteArrivage = async (arrivage) => {
  try {
    const response = await apiClient.delete(`/products/delete-arrivage/${arrivage}`);
    return response.data;
  } catch (error) {
    console.error('Error Deleting Arrivage:', error);
    throw error;
  }
};

export const deleteStockPlus = async (stock) => {
  try {
    const response = await apiClient.delete(`/frais_stock_plus/${stock}`);
    return response.data;
  } catch (error) {
    console.error('Error Deleting Stock Plus:', error);
    throw error;
  }
};

export const restoreBrand = async (brandId) => {
  try {
    const response = await apiClient.post(`/brands/${brandId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring Brand:', error);
    throw error;
  }
};

export const restoreProduct = async (product) => {
  try {
    const response = await apiClient.post(`/products/${product}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring Product:', error);
    throw error;
  }
};

export const restoreCategory = async (category) => {
  try {
    const response = await apiClient.post(`/category/${category}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring Category:', error);
    throw error;
  }
};
export const restoreSeller = async (sellerId) => {
  try {
    const response = await apiClient.post(`/sellers/${sellerId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring seller:', error);
    throw error;
  }
};
export const restorePromotionCode = async (promoId) => {
  try {
    const response = await apiClient.post(`/promotion-codes/${promoId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring promo code:', error);
    throw error;
  }
};
export const restoreRole = async (roleId) => {
  try {
    const response = await apiClient.post(`/roles/${roleId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring role:', error);
    throw error;
  }
};
export const restoreArrivage = async (restoreId) => {
  try {
    const response = await apiClient.post(`/products/${restoreId}/restore-arrivage`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring arrival:', error);
    throw error;
  }
};
export const restorePromotionFlash = async (flashId) => {
  try {
    const response = await apiClient.post(`/promotions-flash/${flashId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring flash promotion:', error);
    throw error;
  }
};
export const restoreFraisStockPlus = async (fraisId) => {
  try {
    const response = await apiClient.post(`/frais_stock_plus/${fraisId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring frais stock:', error);
    throw error;
  }
};
export const restoreProvider = async (providerId) => {
  try {
    const response = await apiClient.post(`/providers/${providerId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring provider:', error);
    throw error;
  }
};
export const restoreShippingCateg = async (catId) => {
  try {
    const response = await apiClient.post(`/category_shippings/${catId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring shipping category:', error);
    throw error;
  }
};
export const restoreShippingMode = async (modeId) => {
  try {
    const response = await apiClient.post(`/shipping_modes/${modeId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring shipping mode:', error);
    throw error;
  }
};
export const restoreTax = async (taxId) => {
  try {
    const response = await apiClient.post(`/taxes/${taxId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring tax:', error);
    throw error;
  }
};
export const restorePos = async (posId) => {
  try {
    const response = await apiClient.post(`/point-of-sale/${posId}/restore`);
    return response.data;
  } catch (error) {
    console.error('Error Restoring pos:', error);
    throw error;
  }
};

export const updateProductArrivageStatus = async (data) => {
  try {
    const response = await apiClient.post(`/products/update-product-arrivage-status/${data.arrivageId}/${data.productId}`);
    return response.data;
  } catch (error) {
    console.error("Error updating product arrivage status:", error);
    throw error;
  }
};

export const updateProductArrivage = async (arrivageId, productId, data) => {
  try {
    const response = await apiClient.put(`/products/update-arrivage/${arrivageId}/${productId}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating product arrivage:", error);
    throw error;
  }
};
