import { useQuery, useMutation } from "@tanstack/react-query";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import React, { useState } from "react";
import { FaCircleMinus } from "react-icons/fa6";
import {
  getAllPointOfSales,
  GetProductBarcodes,
  GetProductsBySupplier,
  getProviders,
  createArrivage,
} from "UpdatedFeatures/api";
import { useSnackBar } from "hooks/useSnackBar";
import { Router, useNavigate } from "react-router-dom";

const AddTransfer = () => {
  const [fournisseur, setFournisseur] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [barcodes, setBarcodes] = useState([]);
  const [products, setProducts] = useState([]);
  const {
    data: productsSupplier,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["productsSupplier", fournisseur],
    queryFn: () => GetProductsBySupplier(fournisseur),
    enabled: !!fournisseur,
  });
  console.log("mlkjh", productsSupplier)
  const { data: providers } = useQuery({
    queryKey: ["providers"],
    queryFn: () => getProviders(),
  });
  const { data: posData } = useQuery({
    queryKey: ["pointOfSales"],
    queryFn: () => getAllPointOfSales(),
  });
  console.log("gt", providers)
  const {
    data,
    isLoading: barcodesLoading,
    error: barcodesError,
  } = useQuery({
    queryKey: ["productBarcodes", selectedProductId],
    queryFn: () => {
      console.log("Fetching barcodes for product ID:", selectedProductId);
      return GetProductBarcodes(selectedProductId);
    },
    enabled: !!selectedProductId,
  });

  console.log("Fetched Barcodes:", data);
  const navigate = useNavigate()

  const { displaySuccessSnackBar, displayErrorSnackBar } = useSnackBar();

  const { mutate: submitArrivage, isLoading: isSubmitting } = useMutation({
    mutationFn: createArrivage,
    onSuccess: () => {
      displaySuccessSnackBar("Arrivage créé avec succès");
      navigate("/gestion-stock");
    },
    onError: (error) => {
      displayErrorSnackBar(error.message || "Erreur lors de la création de l'arrivage");
    }
  });

  const handleFournisseurChange = (e) => {
    const selectedFournisseur = e.target.value;
    setFournisseur(selectedFournisseur);

    if (selectedFournisseur) {
      setProducts([
        {
          product: "",
          arrivageQty: "",
          prixAchat: "",
          codeBar: "",
          kdQuantity: "",
          distribution: [
            ...posData?.data.map((pos) => ({
              posId: pos.id,
              posName: pos.name,
              qty: "",
            })),
          ],
        },
      ]);
    } else {
      setProducts([]);
    }
  };

  const addProductEntry = () => {
    setProducts((prev) => [
      ...prev,
      {
        product: "",
        arrivageQty: "",
        prixAchat: "",
        codeBar: "",
        kdQuantity: "",
        distribution: [
          ...posData?.data.map((pos) => ({
            posId: pos.id,
            posName: pos.name,
            qty: "",
          })),
        ],
      },
    ]);
  };

  const removeProductEntry = (index) => {
    setProducts((prev) => prev.filter((_, i) => i !== index));
  };

  const updateProductField = (index, field, value) => {
    if (field === "prixAchat" || field === "kdQuantity") {
      if (parseFloat(value) < 0) return;
    }

    setProducts((prev) =>
      prev.map((product, i) =>
        i === index ? { ...product, [field]: value } : product
      )
    );

    if (field === "product") {
      setSelectedProductId(value);
    }
  };

  const updateDistributionQty = (productIndex, posId, value) => {
    if (parseFloat(value) < 0) return;

    setProducts((prev) =>
      prev.map((product, i) =>
        i === productIndex
          ? {
            ...product,
            distribution: product.distribution.map((dist) =>
              dist.posId === posId ? { ...dist, qty: value } : dist
            ),
          }
          : product
      )
    );
  };

  const handleSubmit = () => {
    const payload = {
      provider_id: parseInt(fournisseur),
      date: new Date().toISOString().split('T')[0].replace(/-/g, ''),
      products: products.map(product => ({
        id: parseInt(product.product),
        barcode: product.codeBar,
        price: parseFloat(product.prixAchat),
        kdQuantity: parseInt(product.kdQuantity || 0),
        point_of_sales: product.distribution
          .filter(dist => dist.qty)
          .map(dist => ({
            id: parseInt(dist.posId),
            quantity: parseInt(dist.qty)
          }))
      }))
    };

    console.log('Submitting payload:', payload);
    submitArrivage(payload);
  };

  return (
    <DashboardLayout title="Ajouter Transfert" selectedPath={RouteIdEnum.Stock}>
      <div className="flex w-full justify-between items-center mb-6">
        <div className="flex-1">
          <label
            htmlFor="fournisseur"
            className="block text-lg font-semibold text-gray-700"
          >
            Fournisseur
          </label>
          <select
            id="fournisseur"
            value={fournisseur}
            onChange={handleFournisseurChange}
            className="w-full mt-2 p-2 border border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400 rounded-md"
          >
            <option value="">Choisissez un fournisseur</option>
            {providers?.map((provider) => (
              <option key={provider.id} value={provider.id}>
                {provider.company_name}
              </option>
            ))}
          </select>
        </div>
        <div className="ml-6 flex-1">
          <label
            htmlFor="date"
            className="block text-lg font-semibold text-gray-700"
          >
            Date
          </label>
          <input
            id="date"
            type="date"
            value={new Date().toISOString().split("T")[0]}
            className="w-full mt-2 p-2 border border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400  rounded-md"
            disabled
          />
        </div>
      </div>

      {fournisseur && isLoading && <p>Loading products...</p>}
      {fournisseur && error && <p>Error loading products</p>}

      {fournisseur &&
        products.map((product, index) => (
          <div
            key={index}
            className="bg-white pt-12 relative w-full max-w-5xl p-6 border border-gray-300 rounded-3xl shadow-md mb-6"
          >
            <FaCircleMinus
              className="text-red-500 absolute top-5 right-10 cursor-pointer hover:text-red-400"
              size={26}
              onClick={() => removeProductEntry(index)}
            />
            <div className="grid grid-cols-3 gap-4 mb-4">
              <div>
                <label
                  htmlFor={`product-${index}`}
                  className="block text-lg font-semibold text-gray-700"
                >
                  Produit
                </label>
                <select
                  id={`product-${index}`}
                  value={product.product}
                  onChange={(e) =>
                    updateProductField(index, "product", e.target.value)
                  }
                  className="w-full mt-2 p-2 border border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400  rounded-md"
                >
                  <option value="">Choisissez un produit</option>
                  {productsSupplier?.data?.map((prod) => (
                    <option key={prod.id} value={prod.id}>
                      {prod.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor={`prixAchat-${index}`}
                  className="block text-lg font-semibold text-gray-700"
                >
                  Prix d'achat
                </label>
                <input
                  id={`prixAchat-${index}`}
                  type="number"
                  min="0"
                  value={product.prixAchat}
                  onChange={(e) =>
                    updateProductField(index, "prixAchat", e.target.value)
                  }
                  className="w-full mt-2 p-2 border border-purple-300 focus:border-purple-400 focus:ring focus:ring-purple-400 focus:ring-offset-1 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor={`codeBar-${index}`}
                  className="block text-lg font-semibold text-gray-700"
                >
                  Code bar
                </label>
                <select
                  id={`codeBar-${index}`}
                  value={product.codeBar}
                  onChange={(e) => updateProductField(index, "codeBar", e.target.value)}
                  className="w-full mt-2 p-2 border border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400 rounded-md"
                >
                  <option value="">Choisissez un code-bar</option>
                  {data?.map((barcode, i) => (
                    <option key={i} value={barcode}>
                      {barcode}
                    </option>
                  ))}
                </select>


              </div>
            </div>

            <h3 className="text-lg font-semibold text-gray-700 mb-4">
              Répartition
            </h3>
            <div className="mb-4">
              <label
                htmlFor={`kdQuantity-${index}`}
                className="block text-md font-medium text-gray-700"
              >
                KD Marché
              </label>
              <input
                id={`kdQuantity-${index}`}
                type="number"
                min="0"
                value={product.kdQuantity}
                onChange={(e) => updateProductField(index, "kdQuantity", e.target.value)}
                className="w-full mt-2 p-2 border border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400 rounded-md"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              {product.distribution.map(({ posId, posName, qty }) => (
                <div key={posId}>
                  <label
                    htmlFor={`dist-${index}-${posId}`}
                    className="block text-md font-medium text-gray-700"
                  >
                    {posName}
                  </label>
                  <input
                    id={`dist-${index}-${posId}`}
                    type="number"
                    min="0"
                    value={qty}
                    onChange={(e) => updateDistributionQty(index, posId, e.target.value)}
                    className="w-full mt-2 p-2 border border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400 rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>
        ))}

      {fournisseur && (
        <div className="flex justify-end gap-4">
          <button
            onClick={addProductEntry}
            className="text-white bg-[#b269ae] hover:bg-[#9c58a3] px-4 py-2 rounded-md"
          >
            +
          </button>
        </div>
      )}

      {products.length > 0 && (
        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className="mt-4 text-green-700 border border-green-600 bg-green-600/30 hover:bg-green-500/30 px-4 py-2 rounded-md"
        >
          {isSubmitting ? "Sauvegarde en cours..." : "Sauvegarder l'arrivage"}
        </button>
      )}
    </DashboardLayout>
  );
};

export default AddTransfer;
