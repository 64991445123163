import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import TrashedSwitch from "UpdatedComponents/TrashedSwitch";
import { getArrivage, deleteArrivage, restoreArrivage } from "UpdatedFeatures/api";
import Pagination from "UpdatedComponents/Pagination";
import { Puff } from "react-loader-spinner";
import DeleteArrivageModal from "./DeleteArrivageModal";
import { useSnackBar } from "hooks/useSnackBar";
import { MdOutlineRestorePage } from "react-icons/md";
import RestoreModal from "UpdatedFeatures/RestoreModal";
import { toast } from "react-toastify";
import Badge from "UpdatedFeatures/Badge";
import ChangeArrivageStatusModal from "./ChangeArrivageStatusModal";
import EditArrivageModal from "./EditArrivageModal";
import StatusFilter from "UpdatedFeatures/StatusFilter";

const formatDate = (dateString) => {
  if (!dateString) return '';

  const date = String(dateString);
  const year = date.substring(0, 4);
  const month = date.substring(4, 6);
  const day = date.substring(6, 8);
  return `${year}/${month}/${day}`;
};

export default function StockManagementTable() {
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isArchived, setIsArchived] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedArrivage, setSelectedArrivage] = useState(null);
  const [restoreModalOpen, setRestoreModalOpen] = useState(false);
  const [itemToRestore, setItemToRestore] = useState(null);
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [selectedArrivageForReception, setSelectedArrivageForReception] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedArrivageForEdit, setSelectedArrivageForEdit] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({ id: null, label: "All" });
  console.log(selectedStatus)
  const STATUS = {
    Pending: 0,
    Approved: 1,
    Active: 2,
    All: null,
  };

  const statusOptions = [
    { id: STATUS.Pending, label: "En cours d'arrivage" },
    { id: STATUS.Approved, label: "Partiellement réceptionné" },
    { id: STATUS.Active, label: "Réceptionné" },
    { id: STATUS.All, label: "All" },
  ];

  const queryClient = useQueryClient();
  const { displaySuccessSnackBar, displayErrorSnackBar } = useSnackBar();

  const { data: Arrivage, isLoading, isError } = useQuery({
    queryFn: () => getArrivage(page, perPage, search || '', isArchived, selectedStatus.id),
    queryKey: ['arrivage', page, perPage, search, isArchived, selectedStatus.id]
  });

  const totalPages = Math.ceil((Arrivage?.total || 0) / perPage);

  const handleNextPage = () => {
    setPage(prev => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setPage(prev => Math.max(prev - 1, 1));
  };

  const processedArrivages = Arrivage?.data.map((arrivage) => {
    const numberOfProducts = arrivage.products.length;
    return {
      ...arrivage,
      numberOfProducts,
    };
  });
  console.log(Arrivage)
  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(search);
  };

  const { mutate: deleteArrivageMutation, isLoading: isDeleting } = useMutation({
    mutationFn: deleteArrivage,
    onSuccess: () => {
      displaySuccessSnackBar("Arrivage supprimé avec succès");
      queryClient.invalidateQueries(['arrivage']);
      setDeleteModalOpen(false);
    },
    onError: (error) => {
      displayErrorSnackBar(error.message || "Erreur lors de la suppression");
    }
  });

  const handleDeleteClick = (arrivage) => {
    setSelectedArrivage(arrivage);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedArrivage) {
      deleteArrivageMutation(selectedArrivage.id);
    }
  };
  const restoreMutation = useMutation({
    mutationFn: (id) => restoreArrivage(id),
    onSuccess: () => {
      toast.success("Arrivage restauré avec succès !");
      queryClient.invalidateQueries(["arrivage", page, perPage, search]);
      setRestoreModalOpen(false);
    },
    onError: (error) => {
      toast.error(
        `Erreur lors de la restauration : ${error.response?.data?.error || error.message
        }`
      );
      setRestoreModalOpen(false);
    },
  });
  const handleRestoreClick = (item) => {
    setItemToRestore(item);
    setRestoreModalOpen(true);
  };
  const handleRestoreConfirm = () => {
    if (itemToRestore) {
      restoreMutation.mutate(itemToRestore.id);
    }
  };

  const handleReceptionClick = (arrivage) => {
    setSelectedArrivageForReception(arrivage);
    setChangeStatusModalOpen(true);
  };

  const handleEditClick = (arrivage) => {
    console.log(arrivage)
    if (arrivage.status === 2) {
      return toast.warn("Cet Arrivage est completement Receptionné!")
    }
    setSelectedArrivageForEdit(arrivage);
    setEditModalOpen(true);
  };

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] gap-4">
        <span className="text-red-600 font-medium text-lg">
          Une erreur s'est produite lors du chargement des données
        </span>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-[#b269ae] text-white rounded-md hover:bg-[#9c58a3] transition-colors"
        >
          Réessayer
        </button>
      </div>
    );
  }


  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-12">
      <div className="sm:flex sm:items-center justify-between">
        <div className="relative w-1/3">
          <form onSubmit={handleSearchSubmit} className="flex items-center">
            <input
              type="text"
              placeholder="Rechercher..."
              value={search}
              onChange={handleSearchChange}
              className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
            />
            <button
              type="submit"
              className="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <IoSearch color="purple" />
            </button>
          </form>
        </div>
        <div className="flex items-center gap-3">
          <StatusFilter
            statusOptions={statusOptions}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            STATUS={STATUS}
          />
          <TrashedSwitch enabled={isArchived} setEnabled={setIsArchived} />
          <div className="mt-4 sm:mt-0 flex items-center gap-3">
            <Link
              to={"/arrivage/add"}
              type="button"
              className="flex items-center justify-center px-3 py-2 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
            >
              <span className="text-sm">+ Ajouter Arrivage</span>
            </Link>
          </div>
        </div>

      </div>
      {
        isLoading ?
          <div className="flex items-center justify-center min-h-[400px]">
            <Puff
              visible={true}
              height="80"
              width="80"
              color="#895a7c"
              ariaLabel="puff-loading"
            />
          </div> :
          (<div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  {
                    !Arrivage?.data?.length ?
                      (<div className="flex items-center justify-center min-h-[400px]">
                        <span className="text-gray-500 font-medium text-lg">
                          Aucun arrivage trouvé
                        </span>
                      </div>) :
                      (
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-[#b269ae]">
                            <tr>
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6">
                                Date
                              </th>
                              <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                Id Arrivage
                              </th>
                              <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                Fournisseur
                              </th>
                              <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                Nombre de produit Total
                              </th>
                              <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                Status
                              </th>
                              <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Actions</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {processedArrivages?.map((item) => (
                              <tr key={item.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {formatDate(item.date)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.id}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.provider.company_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {item.numberOfProducts}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <Badge status={item.status} type={"arrivage"} />
                                </td>
                                {isArchived ?
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <button className="text-[#b269ae] hover:text-purple-300" onClick={() => handleRestoreClick(item)}>
                                      <MdOutlineRestorePage size={30} className="text-[#b369ae] hover:cursor-pointer " />
                                    </button>
                                  </td> :
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center justify-end">
                                    <button
                                      onClick={() => handleReceptionClick(item)}
                                      disabled={item.status === 2}
                                      className="flex items-center justify-center px-3 py-1 rounded-full bg-[#b269ae]/20 border border-[#b269ae] text-[#b269ae] font-semibold transition-all duration-300 mr-3 disabled:cursor-not-allowed disabled:bg-gray-600/10 disabled:text-gray-600/40 disabled:border-gray-600/40"
                                    >
                                      <span className="text-sm">Réceptionner</span>
                                    </button>
                                    <button
                                      onClick={() => handleEditClick(item)}
                                      className="text-[#b269ae] hover:text-purple-300 ml-4"
                                    >
                                      <FaPen />
                                    </button>
                                    <button
                                      className="text-[#b269ae] hover:text-purple-300 ml-4"
                                      onClick={() => handleDeleteClick(item)}
                                    >
                                      <FaTrash />
                                    </button>
                                  </td>
                                }
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )
                  }
                </div>
              </div>
            </div>
          </div>)
      }

      <Pagination
        prevPage={handlePrevPage}
        nextPage={handleNextPage}
        totalPages={totalPages}
        setPage={setPage}
      />

      <DeleteArrivageModal
        open={deleteModalOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteModalOpen(false)}
        isLoading={isDeleting}
      />

      <RestoreModal
        open={restoreModalOpen}
        onConfirm={handleRestoreConfirm}
        onCancel={() => setRestoreModalOpen(false)}
        itemTitle={itemToRestore?.provider?.company_name}
      />

      <ChangeArrivageStatusModal
        open={changeStatusModalOpen}
        setOpen={setChangeStatusModalOpen}
        selectedArrivage={selectedArrivageForReception}
      />

      <EditArrivageModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        selectedArrivage={selectedArrivageForEdit}
      />

    </div>
  );
}
